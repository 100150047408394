import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const FlagIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3.5C6.26522 3.5 6.51957 3.60536 6.70711 3.79289C6.89464 3.98043 7 4.23478 7 4.5V5.38C8.06 4.94 9.5 4.5 11 4.5C14 4.5 14 6.5 16 6.5C19 6.5 20 4.5 20 4.5V12.5C20 12.5 19 14.5 16 14.5C13 14.5 13 12.5 11 12.5C8 12.5 7 14.5 7 14.5V21.5H5V4.5C5 4.23478 5.10536 3.98043 5.29289 3.79289C5.48043 3.60536 5.73478 3.5 6 3.5ZM7 7.75V12C7 12 9 10.5 11 10.5C13 10.5 14 12.5 16 12.5C18 12.5 18 11.5 18 11.5V8C18 8 17 8.5 16 8.5C14 8.5 13 6.5 11 6.5C9 6.5 7 7.75 7 7.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FlagIcon;
